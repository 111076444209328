import autosize from '@github/textarea-autosize';

// radioボタンでソート
const initRadioSort = (): void => {
  const elements = document.querySelectorAll<HTMLInputElement>('.js-radio-sort');
  if (elements.length === 0) return;

  elements.forEach((element) => {
    element.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('sort', target.value);
      window.location.search = urlParams.toString();
    });
  });
};

// formでenterでsubmitされるのを抑制
const initDisableEnterSubmit = (): void => {
  const elements = document.querySelectorAll<HTMLInputElement>('.js-disable-enter-submit');
  if (elements.length === 0) return;

  elements.forEach((element) => {
    element.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Enter') event.preventDefault();
    });
  });
};

const initTextAreaAutoSize = (): void => {
  const elements = document.querySelectorAll<HTMLInputElement>('.js-textarea-auto-size');
  if (elements.length === 0) return;

  elements.forEach((element) => {
    autosize(element);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initRadioSort();
  initDisableEnterSubmit();
  initTextAreaAutoSize();
});
